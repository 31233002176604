

.element-select__dropdown-menu {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 0;
}

.color-select__dropown-menu {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    margin: 0;
}

.element-select__top-dropdown-item {
    text-align: center!important;
    width: 80%!important; 
}

.element-select__dropdown-item {
    text-align: center;
    width: 3rem, 
}

.element-select__dropdow-item:hover {
    font-weight: 700;
}


