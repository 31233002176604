.copo-selection--mobile {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.copo-info-container--mobile {
    height: auto;
    order: 2;
}

.copo-map-container--mobile {
    height: 40vh;
    border: 4px solid #E0E0E0;
    border-radius: 10px;
    order: 1;
}

