div#root {

}
  
.App {
    background-color: var(--bg-color-2);
    text-align: left;
    display: flex;
    flex-direction: column;
}

.laboratory-page {
    flex-grow: 1;
    max-height: 100vh;
}
  
.navbar {
    background-color: var(--bg-color-1);
    padding-left: 10px!important;
    padding-right: 10px!important;
    min-height: 8vh!important;
}

.settings-header {
    width: 100%;
    height: auto; 
    padding: 0.5rem;
    border-bottom: 2px solid rgb(80, 80, 80);
    font-family: 'Roboto', sans-serif;
}

.settings-title {
    font-size: 1.2rem;
    padding-bottom: 0.3rem;
    display: "flex";
    align-items: "center";
}

.left-panel .accordion-button {
    width: 100%!important;
}

/* CONTROL SETTINNGS */

.control-settings__content {
    padding-top: 3.3rem;
    padding-left: 4%;
    padding-right: 6%;
    opacity: 1;
    transition: opacity 1s ease-in-out;
}

.control-settings__subtitle {
    display: flex;
    align-items: center;
    text-align: left; 
    font-size: 1.2rem;
    margin-left: 2%; 
    margin-right: 2%;
    padding-bottom: 0.2em;
}

.control-settings__form-label {
    color: var(--color-1);
    font-size: 0.8rem;
    margin-bottom: 2px!important;
}

/* VIEWPORT */

.viewport__image {
    border: #FFF 5px solid;
    margin: 0.5em;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

/* Controls how the image will appear inside the frame */
.viewport__frame {
    background-color: var(--bg-color-3);
    position: relative;
    display: flex; 
    justify-content: center;
    align-items: center;
    border-radius: 0.5em;
    padding: 0.5em;
    padding-top: 3em;
    height: 100%; 
    width: 100%;
    overflow-x: hidden;
}

/* FILES */

.footer__button {
    width: 100%!important;
    border-radius: 0 !important;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    border: 0px!important;
    height: 3.5em; 
}

.footer__button:hover {
    height: 4em;
}


/* BUTTONS */ 

.gotocart__button {
    background-color: var(--green-light)!important;
}

.gotocart__button:hover {
    background-color: var(--green-dark)!important;
}

.control-run__activate-random {
    border: 3px solid white!important;
    color: var(--random-light) !important;
    background-color: var(--bg-color-1)!important;
}

.control-run__activate-random:hover {
    color: #FFF !important;
    font-weight: 700!important;
    background-color: var(--random-dark) !important;
}

.control-run__button--portrait {
    background-color: var(--portrait-light)!important;
}

.control-run__button--portrait:hover {
    background-color: var(--portrait-dark) !important;
}

.control-run__button--abstract {
    background-color: var(--abstract-light) !important;
}

.control-run__button--abstract:hover {
    background-color: var(--abstract-dark) !important;
}

.control-run__button--random {
    background-color: var(--random-light) !important;
}

.control-run__button--random:hover {
    background-color: var(--random-dark)!important;
}



