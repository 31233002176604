.copo-selection {
    height: 22rem;
    display: flex;
    flex-direction: row;
    gap: 1.5em;
}

.copo-info-container {
    width: 40%;
}

.copo-map-container {
    height: 100%;
    flex-grow: 1;
    border: 4px solid #E0E0E0;
    border-radius: 10px;
}