.laboratory-page {
    flex-grow: 1;
    height: 92vh;
    max-height: 92vh;
    overflow: hidden;
}

/* LEFT PANEL */

.left-panel {
    border-right: 1px solid var(--color-3);
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    height: 92vh; 
    max-height: 92vh;
}

.left-panel__control-settings {
    background-color: var(--bg-color-3);
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 3%, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 97%);
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 3%, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 97%);

    overflow-y: auto;
    scroll-behavior: smooth;
    flex-grow: 1;
}

.control-settings__navlink {
    color: var(--color-2)!important;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem!important;
    font-weight: 100!important;
    border-radius: 5px;
    margin: 2px;
}

.control-settings__navlink:hover {
    border: 2px solid var(--color-2)!important;
    border-radius: 3px;
    font-weight: 500!important;
    background-color: rgba(255, 255, 255, 0.5)!important;
}

.control-settings__card{
    background-color: var(--bg-color-5)!important;
}

.control-settings__card-header{
    background-color: var(--bg-color-4)!important;
}

.control-settings__card-body{
    background-color: var(--bg-color-5)!important;
    border-radius: 0 0 5px 5px!important;
    padding-top: "5px";
}

.control-settings__card-toggle:hover {
    font-weight: 700!important;
}


/* CENTER PANEL */

.center-panel {
    max-height: 92vh;
    display: flex;
    justify-content: center;
    padding: 0.5em; 
}

/* Positionning on the frame */
.center-panel__viewport {
    display: flex; 
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}







/* RIGHT PANEL */

.right-panel {
    border-left: 1px solid var(--color-3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.right-panel__file-panel {
   
}

.file-panel__filename {
    padding: 0.5rem;
    font-size: 3rem; 
    font-weight: 400;
    display: flex;
    align-items: center;
}



