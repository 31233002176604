.slider {
    perspective: 1000px;
    transform-style: preserve-3d;  
}

.slider-mobile {
    width: 200vw;
    transform: translateX(-25%);
}

.scroller {
    max-width: 200vw;
    overflow: hidden;

    transform-style: preserve-3d;

    mask-image: 
        linear-gradient(to right, transparent, white  20%, white 80%, transparent);
    -webkit-mask-image:  
        linear-gradient(to right, transparent, white  20%, white 80%, transparent);
}


.scroller-top {

    position: relative;
    z-index: 2;

    transform: 
        translateX(10%)
        rotateY(50deg)
        rotateX(40deg)
        scale(1.2)
        rotateZ(-4deg);


    filter: brightness(0.9);

    mask-image: 
        linear-gradient(to right, transparent, white 10%, white 50%, transparent 100%);
    -webkit-mask-image:  
        linear-gradient(to right, transparent, white 10%, white 50%, transparent 100%);

}


.scroller-bottom {

    position: relative;
    z-index: -1;

    filter: brightness(0.6);

    transform: 
        translateX(0%)
        translateY(2%)
        rotateY(-60deg)
        rotateZ(4deg)
        scale(1.2)
        rotateX(40deg);

    mask-image:
        linear-gradient(to left,  transparent, white 10%, white 50%, transparent 100%);
    -webkit-mask-image:
        linear-gradient(to left,  transparent, white 10%, white 50%, transparent 100%);

}


.scroller .scroller__inner {
    display: flex;
    gap: 2rem; 
    padding-block: 1rem;
    width: fit-content;
    flex-wrap: nowrap;
    animation: 
        scroll 
        var(--_animation-duration, 60s) 
        var(--_animation-direction, forwards)
        linear 
        0.1s
        infinite;

    transform-style: preserve-3d;
}

.scroller[data-direction='right'] {
    --_animation-direction: reverse;
}

@keyframes scroll {
    to {
        transform: translateX(calc(-50%));
    }
}

@-moz-document url-prefix() {
    .scroller .scroller__inner {
        animation: none;
    }
}