.checkout-page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.button-backtocart {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 1.1em;
    font-weight: 500;
}

.checkout-container {
    display: flex;
    flex-flow: row no-wrap;
    justify-content: space-between;
    gap: 2rem; 
    margin: 3rem;
    width: 90%;
    height: 80%; 
}

.shipping-container {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.payment-container {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.section-title {
    font-weight: 700;
    color: var(--color-1);
    font-size: 1em;
    padding-bottom: 0.5em;
    display: flex;
    align-items: center;
    margin-left: 0.5em;
}

.section {
    background-color: #F0F0F0;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.section-shipping {
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.section-with-button {
    border-radius: 10px 10px 0 0;
}

.section-bottom {
    margin-top: auto;
    padding-top: 3em;
    display: flex;
    flex-direction: column;
}

.section-formlabels {
    font-size: small;
    margin-bottom: 0.2rem!important;
}

.section-button {
    width: 100%;
    border-radius: 0 0 10px 10px!important;
    height: 3rem;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
}

.copo-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.button-shipmethod {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.1em;
    padding-right: 0.5em;
    padding-left: 0.5em;
    border-radius: 5px;
    font-size: small!important;
    font-weight: 700!important;
    border: 2px solid #DDD!important;
    background-color: white!important;
    color: #DDD!important;
}

.button-shipmethod:hover {
    border: 2px solid var(--green-light)!important;
    background-color: #FAFAFA!important;
    color: #555!important;
}

.button-shipmethod:active {
    border: 2px solid #BBB!important;
    background-color: rgb(235, 235, 235)!important;
    color:   #888!important;
}

.button-shipmethod--selected {
    border: 2px solid var(--green-dark)!important;
    background-color: #FAFAFA!important;
    color: #333!important;
}

.terms-checkbox {
    min-height: 1.8em!important;
    min-width: 1.8em!important;
    display: flex;
    align-items: center;
    padding: 0!important;
    margin: 0!important;
    color: #DDD!important;
    background-color: #FAFAFA!important;
    border: 2px solid #DDD!important;
}

.terms-link {
    color: #AAA!important;
}

.terms-warning {
    color: rgb(220, 53, 69);
    font-size: small;
    margin-top: 0.2em;
}

.be-centered {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


