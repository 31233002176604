.primary-text {
    color: var(--color-1)
}

.secondary-text {
    color: var(--color-2)
}

.faded-text {
    color: var(--color-3)
}

.shadowed-text {
    /*text-shadow: 
        0px 10px 10px rgba(0, 0, 0, 0.6),
        10px 0px 10px rgba(0, 0, 0, 0.6);*/
}  

.control-settings__subtitle {
    color: var(--color-1);
    border-bottom: 1px solid var(--color-3);
    font-weight: 550;
}

.settings-title {
    color: var(--color-1);
    font-weight: 550;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.button-text {
    cursor: pointer;
    color: var(--color-3);
}

.button-text:hover {
    color: var(--color-1);
    font-weight: bold;
    text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5)!important;
}




