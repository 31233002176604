html {
    font-family: Roboto, Helvetica, sans-serif;
    scroll-behavior: smooth;
    height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #222;
}

p {
  line-height: 1.6; /* Ajustez l'espacement des lignes */
  margin-bottom: 20px; /* Ajustez l'espacement entre les paragraphes */
  text-align: justify; /* Alignez le texte */
  text-indent: 10px; /* Indentez la première ligne de chaque paragraphe */
}

:root {
  --bg-color-1: #333;
  --bg-color-2: #444;
  --bg-color-3: #555;
  --bg-color-4: #666;
  --bg-color-5: #888;

  --color-1: #EEE;
  --color-2: #CCC;
  --color-3: #AAA;

  --portrait-light: hsl(180, 33%, 55%);
  --portrait-dark: hsl(180, 100%, 25%);

  --abstract-light: hsl(200, 48%, 61%);
  --abstract-dark: hsl(201, 32%, 44%);

  --random-light:hsl(47, 97%, 76%);
  --random-dark: hsl(47, 100%, 68%);

  --green-light: hsl(131, 30%, 66%);
  --green-dark: hsl(131, 32%, 59%);
}

[data-theme="dark"] {
    --bg-color-1: #333;
    --bg-color-2: #383838;
    --bg-color-3: #444;
    --bg-color-4: #484848;
    --bg-color-5: #555;

    --color-1: #EEE;
    --color-2: #CCC;
    --color-3: #AAA;
    --color-4: #AAA;

    --portrait-light: hsl(180, 33%, 55%);
    --portrait-dark: hsl(180, 100%, 25%);

    --abstract-light: hsl(200, 48%, 61%);
    --abstract-dark: hsl(201, 32%, 44%);

    --random-light:hsl(0, 71%, 69%);
    --random-dark: hsl(0, 64%, 60%);

    --green-light: hsl(131, 30%, 66%);
    --green-dark: hsl(131, 32%, 59%);
}

[data-theme="light"] {
  --bg-color-1: #DFE4E7;;
  --bg-color-2: #c4cbcf;
  --bg-color-3: #e4e7e9;
  --bg-color-4: #edeff1;
  --bg-color-5: #f6f7f8;

  --color-1: #333;
  --color-2: #444;
  --color-3: #666;
  --color-4: #a4adb3;

  --portrait-light: hsl(180, 33%, 55%);
  --portrait-dark: hsl(180, 100%, 25%);

  --abstract-light: hsl(200, 41%, 55%);
  --abstract-dark: hsl(201, 42%, 36%);

  --random-light:hsl(0, 71%, 69%);
  --random-dark: hsl(0, 64%, 60%);

  --green-light: hsl(131, 32%, 61%);
  --green-dark: hsl(131, 29%, 54%);
}

[data-theme="light-1"] {
  --bg-color-1: #DFE4E7;;
  --bg-color-2: #AAA;
  --bg-color-3: #DDD;
  --bg-color-4: #EEE;
  --bg-color-5: #FFF;

  --color-1: #333;
  --color-2: #444;
  --color-3: #666;

  --portrait-light: hsl(180, 33%, 55%);
  --portrait-dark: hsl(180, 100%, 25%);

  --abstract-light: hsl(200, 41%, 55%);
  --abstract-dark: hsl(201, 42%, 36%);

  --random-light:hsl(47, 97%, 76%);
  --random-dark: hsl(47, 100%, 68%);

  --green-light: hsl(131, 30%, 66%);
  --green-dark: hsl(131, 32%, 59%);
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
