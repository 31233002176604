.scroll-fade-top {
    background-color: var(--bg-color-2);
    mask-image: linear-gradient(to bottom,  rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 100%);
    -webkit-mask-image:  linear-gradient(to bottom,  rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 100%);

    position: absolute;
    pointer-events: none;
    top: 0px;
    height: 80px;
    width: 100%;
}

.scroll-fade-bottom {
    background-color:  var(--bg-color-2);
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 100%);
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 100%);

    position: absolute;
    pointer-events: none;
    bottom: 0px;
    height: 80px;
    width: 100%;
}

