.summary-page {
    height: 92vh;
    max-height: 92vh;
    width: 100vw;
    max-width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.summary-container {
    width: 85%;
    height: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* PANELS */

.summary-container__left-panel {
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.summary-container__left-panel__top {
    height: 55%;
    display: flex;
    flex-direction: column;
}

.summary-container__left-panel__bottom {
    height: 40%;
    display: flex;
    flex-direction: column;
}

.summary-container__right-panel {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.summary-container__right-panel__top {
    height: 50%;
    display: flex;
    flex-direction: column;
}

.summary-container__right-panel__bottom {
    height: 45%;
    display: flex;
    flex-direction: column;
}



/* SECTIONS */

.summary-section-title {
    font-weight: 700;
    color: var(--color-1);
    font-size: 1em;
    padding-bottom: 0.5em;
    display: flex;
    align-items: center;
    margin-left: 0.5em;
}


.summary-section {
    border-radius: 10px;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
    background-color: var(--bg-color-3);
    flex-grow: 1;
}

.summary-section--drafts {
    background-color: var(--bg-color-3);

    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: row;
    max-width: 100%;
    overflow-x: auto;

    padding-right: 3em;
    padding-left: 3em;
    gap: 2em;
}

.summary-section--cart {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    max-width: 100%;
    overflow-x: auto;
    gap: 2em;
    position: relative;
}

.summary-section--about {
    color: var(--color-1);
}

.summary-section--info {
    color: var(--color-1);
    padding-right: 10%;
    padding-left: 5%;
}

.summary-section-scroll-fade-top {

    background-color: var(--bg-color-2);
    mask-image: linear-gradient(to bottom,  rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0) 100%);
    -webkit-mask-image:  linear-gradient(to bottom,  rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0) 100%);

    position: absolute;
    pointer-events: none;
    border-radius: 10px 10px 0px 0px;
    top: 0px;
    height: 40px;
    width: 100%;
}

.summary-section-scroll-fade-bottom {
    background-color:  var(--bg-color-2);
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8) 100%);
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8) 100%);

    position: absolute;
    pointer-events: none;
    border-radius: 0px 0px 10px 10px;
    bottom: 0px;
    height: 80px;
    width: 100%;
}

.summary-section--order {
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.image-card__container {
    background-color: #EEE;
    border-radius: 10px;
    height: 70%;
    margin-bottom: 2em;
    position: relative;
}

.image-card__image {
    height: 100%;
    width: auto;
    object-fit: cover;
}

.image-card__size-selection {
    height: 2em;
    position: absolute;
    bottom: -2.2em; 
    width: 100%;
}

.image-card__close-button {
    height: 2em;
    position: absolute;
    top: 0.2em; 
    right: 0.2em;
}

