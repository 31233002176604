.button-with-icon {
    display: flex!important;
    justify-content: space-between!important;
    align-items: center!important;
}

.text-with-icon{
    display: flex!important;
    justify-content: space-between!important;
    align-items: center!important;
}

.button-without-border {
    border: none!important;
}

.button-icon {
    cursor: pointer;
}

.button-icon:hover {
    transform: scale(1.3);
}

.button-color-green {
    color: white!important;
    background-color: var(--green-light)!important;
    cursor: pointer;
}

.button-color-green:hover {
    background-color: var(--green-dark)!important;
    font-weight: 700;
}

.button-image {
    cursor: pointer;
}

.button-image:hover {
    transform: scale(1.3);
}

.button-icon-socials {
    cursor: pointer;
    color: var(--color-3);
    font-size: 2em!important;
}

.button-icon-socials:hover {
    color: var(--color-2);
    font-size: 2.5em!important;
}

.button-icon-val {
    cursor: pointer;
    height: 100%!important;
    font-size: 2.5em!important;
}

.button-icon-val:hover {
    font-size: 2.8em!important;
}

.button-icon-del {
    cursor: pointer;
    height: 100%!important;
    font-size: 2.5em!important;
}

.button-icon-del:hover {
    font-size: 2.8em!important;
}

.button-mockup-selection {
    cursor: pointer;
    border: 0.1em solid #BBB;
    height: 90%;
    width: auto;
    object-fit: cover;
    opacity: 0.7;
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.25);
}

.button-mockup-selection:hover {
    border: 0.2em solid #AAA;
    opacity: 0.9;
    height: 95%;
}

.button-green {
    background-color: var(--green-light);
    border: none;
    font-size: 1.2em;
    border-radius: 0.5em;
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.25);
}

.button-green:hover {
    background-color: var(--green-dark);
}

.button-checkout:hover {
    background-color: var(--green-dark)!important;
    font-weight: 700;
}

.button-in-cart {
    background-color: var(--green-light)!important;
    border: none!important;
    font-size: 1.2em;
}

.button-in-cart:hover {
    background-color: var(--green-dark)!important;
    font-weight: 700!important;
}

.button-not-in-cart {
    background-color: #D87982!important;
    border: none!important;
}

.button-not-in-cart:hover {
    background-color: #C56B74!important;
    font-weight: 700;
}

.anagram-div {
    display: flex;
    position: relative;
    width: 8em; 
    height: 2em;
    align-items: center;
}

.anagram-div .child {
    position: absolute;
    animation-duration: 30s;      /* Duration of the animation */
    animation-timing-function: ease;
    animation-iteration-count: infinite; /* Repeat indefinitely */
    animation-delay: 0;  /* Delay after each animation (15s total cycle time - 2s animation time) */
}

.seed-image{
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.25);
    border-radius: 0.5em;
    border: none;
    opacity: 0.9;
}

.seed-image:hover {
    cursor: pointer;
    transform: scale(1.1);
    opacity: 1;
}

.dynamic-image{
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.25);
    border-radius: 0.5em;
    border: none;
    opacity: 0.9;
}

.dynamic-image:hover {
    cursor: pointer;
    transform: scale(1.1);
    opacity: 1;
}


.navbar-pill {
    color: var(--color-2);
    background-color: var(--bg-color-3);
    border: 1px solid var(--bg-color-4);
    border-radius: 10px;
}

.navbar-pill:hover {
    color: var(--color-1);
    background-color: var(--bg-color-5);
    border: 1px solid var(--bg-color-4);    
}


/* Define keyframes for each child element's movement */
@keyframes move1 {
    0%, 20%, 80%, 100% { left: 0.4em; }
    30%, 70% { left: 5.5em; }
}

@keyframes move2 {
    0%, 20%, 80%, 100% { left: 1.2em; }
    30%, 70%{ left: 4em; }
}

@keyframes move3 {
    0%, 20%, 80%, 100% { left: 2em; }
    30%, 70%{ left: 1em; }
}

@keyframes move4 {
    0%, 20%, 80%, 100% { left: 3em; }
    30%, 70% { left: 3em; }
}

@keyframes move5 {
    0%, 20%, 80%, 100% { left: 4em; top: 0em; }
    30%, 70%  { left: 5em; top: -0.6em;}
}

@keyframes move6 {
    0%, 20%, 80%, 100% { left: 4.5em; }
    30%, 70%  { left: 5em; }
}

@keyframes move7 {
    0%, 20%, 80%, 100% { left: 5em; }
    30%, 70% { left: 2em; }
}

/* Apply the specific animation to each child */
.anagram-div .child:nth-child(1) {
    animation-name: move1;
}

.anagram-div .child:nth-child(2) {
    animation-name: move2;
}

.anagram-div .child:nth-child(3) {
    animation-name: move3;
}

.anagram-div .child:nth-child(4) {
    animation-name: move4;
}

.anagram-div .child:nth-child(5) {
    animation-name: move5;
}

.anagram-div .child:nth-child(6) {
    animation-name: move6;
}

.anagram-div .child:nth-child(7) {
    animation-name: move7;
}


