.psc-container {
    width: 100%;
    padding-top: calc(100% / (4 / 5));
    display: flex;
    justify-content: center;
    align-items: center;
}

.psc-inner-container {
    position: relative;
    width: 95%;
}

.psc-box {
    z-index: 1;
    position: absolute;
    bottom: 0;
    border: solid 3px #CCC;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5%;
    width: 100%;
    padding-top: calc(100% / (4 / 5));
    cursor: pointer;
}

.psc-box-label {
    position: absolute;
    top: 0;
    right: 0.5em;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 0.7em;
}

.psc-box-label__name {
    color: white;
    font-weight: 700;
    font-size: 1em;
}

.psc-box-label__dimensions {
    color: #EEE;
    font-weight: 500;
    font-size: 0.7em;
}

.psc-small-box {
    z-index: 1;
    position: absolute;
    bottom: 10px;
    left: 10px;
    border: solid 3px #CCC;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5%;
    width: 71%;
    padding-top: calc(71% / (4 / 5));
}

.psc-small-box:hover {
    border: solid 4px hsl(151, 32%, 68%);
    background-color: rgba(0, 0, 0, 0.3);
    width: 73%;
    padding-top: calc(73% / (4 / 5));
}

.psc-small-box--selected {
    border: solid 4px #85B09B;
}

.psc-box:hover {
    border: solid 4px hsl(151, 32%, 68%);
    background-color: rgba(0, 0, 0, 0.3);
    width: 102%;
    padding-top: calc(102% / (4 / 5));
}

.psc-box--selected {
    border: solid 4px #85B09B;
}

.psc-reference {
    z-index: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 80%; 
    fill: white;
}