.btn-secondary {
  color: var(--color-2)!important;
  background-color: var(--bg-color-4)!important;
  border-color: var(--bg-color-3)!important;
}

.btn-secondary:hover {
  color: var(--color-2)!important;
  background-color: var(--bg-color-1)!important;
  border-color: var(--bg-color-2)!important;
}

.btn-outline-secondary {
  --bs-btn-color: var(--color-3)!important; 
  --bs-btn-border-color: var(--color-3)!important; 

  --bs-btn-hover-color: #fff!important; 
  --bs-btn-hover-bg: var(--bg-color-5)!important;
  --bs-btn-hover-border-color: var(--bg-color-5)!important; 

  --bs-btn-active-color: var(--color-1)!important; 
  --bs-btn-active-bg: var(--bg-color-5)!important; 
  --bs-btn-active-border-color: var(--color-3)!important; 

  --bs-btn-disabled-color: var(--bg-color-4)!important; 
  --bs-btn-disabled-bg: transparent!important; 
  --bs-btn-disabled-border-color: var(--bg-color-4)!important; 
}

.accordion-button {
    background-color: #EAEAEA !important;
  }
  
.accordion-button:focus {
  box-shadow: none;
  color: #000000 !important;
  background-color: #D5D8DC !important;
}

.accordion-button:hover {
  background-color: #AAAAAA !important;
  color: #222222 !important;
  font-size: 18px;
}

.accordion-button:not(.collapsed) {
  color: #000000 !important;
  background-color: #b2d8d8 !important;
}

.accordion-body {
    background-color: var(--bg-color-5)!important;
}

.form-check-input {
  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23666%27/></svg>") !important;
}

.form-check-input:checked {
  background-color: #666 !important;
  border-color: #6668 !important;
  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23fff%27/></svg>") !important;
}

.form-check-input:focus {
  border-color: #6668 !important;
  outline: 0;
  box-shadow: none !important;
}